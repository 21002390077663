.input-group .form-control {
  width: 170px;
  height: 35px;
  background-color: rgba(61, 81, 112, 1);
  color: #fff;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  padding: 0 5%;
  display: inline-block;
  border: 0;
  margin-bottom: 0px;
  border-radius: 5px;
}

.input-group-btn {
  height: 35px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(61, 81, 112, 1);
}

.imgContainer {
  width: 35px;
  height: 35px;
  padding-top: 4px;
}

.datePickerContainer {
  display: flex;
  justify-content: flex-end;
}

.daterangepicker .calendar-table th{
  color: rgba(61, 81, 112, 1);
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

 .daterangepicker .ranges li {
  color: rgba(61, 81, 112, 1);
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.daterangepicker .ranges li.active:hover {
 color: #fff;
 font-family: 'Open Sans', sans-serif;
 font-weight: 600;
 background-color: rgba(61, 81, 112, 1);
}

.daterangepicker .ranges li.active {
  background-color: rgba(61, 81, 112, 1);
  color: #fff;
}

.daterangepicker td {
  color: rgba(61, 81, 112, 1);
  font-family: 'Open Sans', sans-serif;
}

.daterangepicker td.active.start-date.available, 
.daterangepicker td.active.end-date.available, 
.daterangepicker td.active:hover {
  background-color: rgba(61, 81, 112, 1);
  border-color: transparent;
  color: #fff;
}

.daterangepicker .drp-buttons .btn {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    padding: 4px 8px;
}

.btn.btn-primary {
    background-color: rgba(61, 81, 112, 1);
    border-color: rgba(61, 81, 112, 1);
    margin: 0;
}

.btn:before {
    background: #fff;
}

.btn-default {
  margin: 0;
  color: rgba(61, 81, 112, 1);
  font-family: 'Open Sans', sans-serif;
}

.btn.btn-primary:before {
  background-color: rgba(61, 81, 112, 0.5);
}

.drp-selected {
  color: rgba(61, 81, 112, 0.7);
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}
