.map {

  .infoBox {
    width: 130px !important;
    overflow: visible !important;
    color: #fff;
  }

  .gm-style-pbt {
    color: #ffffff;
  }
}

.map__map-container {
  margin-bottom: 25px;
}

.map__marker-label-content {
  background-color: rgba(61, 81, 112, 1);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  width: 120px;
  // min-height: 40px;
  bottom: -10px;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 4px);
    bottom: -8px;
    border: 4px solid transparent;
    border-top: 4px solid rgba(61, 81, 112, 1);
  }
}

.map__maker-label-close {
  position: absolute;
  width: 12px;
  height: 12px;
  right: 5px;
  top: 5px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
}

.map__marker-label {
  position: absolute;
  top: -90px;
  left: -65px;
}

.legend {
  height: 50px;
  width: 120px;
  background-color: #fff;
  position: absolute;
  top: 5px;
  left: 20px;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 5px;
  box-shadow: 0px 2px 10px rgba(0,0,0,.4);
}

.legend__legendIcon {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.legend--urbin {
  background-color: #40B509;
}

.legend--L1 {
  background-color: #F5A623;
}

.legend__legendBox {
  display: flex;
  flex-direction: row;
}

.legend__text {
  color: rgba(61, 81, 112, 1);
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
}
