.loader-container {
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
}

// .loader {
//   z-index: -10;
//   background-color: green;
// }